import { css } from "@emotion/core"
import formCSS from "css/hubspot-form-light"
import { btn, h3 } from "css/primitives"
import { colors } from "css/theme"
import checkIcon from "src/images/icons/check.svg"

export const wrapper = css`
  background-color: #fafafa;
  margin-top: -100px;
  padding-top: calc(100px + 4rem);
`

export const wrapperInner = css`
  margin-bottom: 3rem;
  max-width: 1125px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: 576px) {
    margin-bottom: 2rem;
  }
`

export const top = css`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 72px;
  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  @media (max-width: 576px) {
    margin-bottom: 3rem;
  }
`

export const topContent = css`
  width: 50%;
  @media (max-width: 1023px) {
    width: 100%;
    max-width: 700px;
    text-align: center;
  }
  @media (max-width: 576px) {
    text-align: left;
  }
  padding-bottom: 2rem;
`
export const category = css`
  font-family: var(--heading);
  font-size: 14px;
  letter-spacing: 0.78;
  text-transform: uppercase;
  color: ${colors.skyblue};
  margin-bottom: 12px;
  font-weight: 600;
`

export const title = css`
  color: black;
  font-family: var(--heading);
  line-height: 1.13;
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 36px;
  max-width: 500px;
  @media (max-width: 1023px) {
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 576px) {
    font-size: 2rem;
  }
`

export const button = [
  btn,
  css`
    background-color: ${colors.orange};
    color: white;
    box-shadow: none;
    transition: box-shadow 0.3s ease;
    &:hover {
      color: white;
      box-shadow: 3px 3px 15px 0 #8c8b8b;
    }
  `,
]

export const topImage = css`
  width: 50%;
  margin-right: -1rem;
  @media (max-width: 1023px) {
    width: 100%;
    max-width: 460px;
    margin-right: 0;
  }
  .gatsby-image-wrapper {
    width: 100%;
    max-height: 575px;
    img {
      object-fit: contain !important;
    }
  }
`

export const main = css`
  background-color: #001f24;
  padding-top: 77px;
  padding-bottom: 77px;
  display: flex;
  @media (max-width: 576px) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
`

export const mainInner = css`
  margin-bottom: 3rem;
  max-width: 1125px;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: 479px) {
    margin-bottom: 50px;
  }
  display: flex;
  justify-content: space-between;
  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
  }
`

export const mainText = css`
  width: 50%;
  padding-right: 1.5rem;
  h2 {
    color: white;
    font-family: var(--heading);
    font-size: 34px;
    font-weight: bold;
    line-height: 1.53;
    margin-bottom: 24px;
    @media (max-width: 576px) {
      font-size: 24px;
    }
  }
  color: #d2d2d2;
  font-size: 17px;
  line-height: 1.65;
  @media (max-width: 576px) {
    font-size: 1rem;
  }
  p {
    margin-bottom: 21px;
  }
  ul li {
    list-style: none;
    position: relative;
    margin-bottom: 1.5rem;
    &::before {
      content: "";
      width: 16px;
      position: absolute;
      top: 7px;
      left: -1.6rem;
      height: 16px;
      background-image: url(${checkIcon});
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  @media (max-width: 1023px) {
    margin-bottom: 2rem;
    width: 100%;
    max-width: 700px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`
export const form = css`
  width: 50%;
  max-width: 460px;
  margin-left: auto;
  @media (max-width: 1023px) {
    width: 100%;
    margin-right: auto;
  }
`

export const formWrapper = [
  formCSS,
  css`
    background-color: white;
    box-shadow: 2px 2px 10px 0 rgba(207, 207, 207, 0.52);
    border-radius: 8px;
    width: 100%;
    max-width: 460px;
    min-height: 520px;
    padding: 2.25rem 2.5rem;
    padding-bottom: 0.5rem;
    box-shadow: 1px 0 20px 0 black;
    @media (max-width: 991px) {
      margin-top: 0;
    }
    @media (max-width: 767px) {
      padding: 1.5rem;
      padding-bottom: 0;
    }
    @media (max-width: 479px) {
      margin-right: -1rem;
      margin-left: -1rem;
      width: calc(100% + 2rem);
    }
  `,
]
export const formHeading = [
  h3,
  css`
    margin-bottom: 1rem;
    color: black;
  `,
]
